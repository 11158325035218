<template>
  <div>
    <b-card>
      <b-card-header>
        {{ $t('AppAudit.list.search.label') }}
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-input
              v-model="referenceNumber"
              class="d-inline-block mr-1"
              :placeholder="$t('AppAudit.list.search.placeHolder')"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-button
              class="d-inline-block mr-1"
              @click="onSearchReference"
            >
              {{ $t('AppAudit.list.search.btnSearch') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <list
      ref="refOperationsList"
      sort-by="PreauditorOperacionId"
      :sort-dir-desc="false"
      refetch-records-name="operaciones"
      key-field="ParteId"
      report-name="CatalogoPartes.xlsx"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchOperations"
      :upload-template="createImportFile"
      upload-template-select-customer
    >
      <template #cell(fechaAlta)="data">
        <b-media v-if="stateTime.value" vertical-align="center">
          {{ data.item.fechaAlta | formatDateTime }}
        </b-media>
        <b-media v-else vertical-align="center">
          {{ data.item.fechaAlta | formatDate }}
        </b-media>
      </template>
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveOperationStatusVariant(data.item)}`"
          class="text-capitalize"
        >
          {{ data.item.preauditorOperacionEventos.length }} {{ $t('AppAudit.titlefinding')}}
        </b-badge>
      </template>
      <template #cell(freePayment)="data">
        <b-badge
          pill
          :variant="`light-${resolveOperationFreePaymentVariant(data.item)}`"
          class="text-capitalize"
        >
          {{ resolveOperationFreePaymentText(data.item) }}
        </b-badge>
      </template>
    </list>
    <b-modal
      id="modal-findings"
      v-model="showFindingsModal"
      :title="$t('AppAudit.titlefinding')"
      size="xl"
    >
      <list
        ref="refOperationsFindingsList"
        sort-by="PreauditorOperacionEventoId"
        refetch-records-name="hallazgos"
        key-field="PreauditorOperacionEventoId"
        report-name="CatalogoPartes.xlsx"
        :actions="actionsOperationFindings"
        :filters.sync="filtersOperationFindings"
        :table-columns="tableOperationFindingsColumns"
        :table-actions="tableActionsOperationFindings"
        :refetch-records="fetchEvents"
      />
      <template #modal-footer>
        <span />
      </template>
    </b-modal>
    <b-modal
      id="modal-logs"
      v-model="showLogsModal"
      :title="$t('AppAudit.titlelogbook')"
      size="xl"
    >
      <app-timeline>
        <app-timeline-item
          v-for="item in operationLogs"
          :key="item.inicioPedimentoBitacoraId"
          :variant="resolveVariantLog(item.evento)"
        >
          <div>
            {{ item.evento }}
          </div>
          <div>
            {{ item.observaciones }}
          </div>
          <div>
            {{ item.fechaHora }}
          </div>
          <div>
            {{ item.usuario }}
          </div>
        </app-timeline-item>
      </app-timeline>
      <template #modal-footer>
        <span />
      </template>
    </b-modal>
    <b-modal
      id="modal-authorization"
      v-model="showAuthorizationModal"
      :title="$t('AppAudit.titleAuth')"
      size="xl"
    >
      <validation-observer
        ref="refAuthorizationRules"
        tag="form"
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('AppAudit.typeAuth')"
              rules="required"
            >
              <b-form-group
                :label="$t('AppAudit.typeAuth')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="selectedOperationAudit.hallazgoAutorizacionTipoId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="findingTypes"
                  :reduce="option => option.id"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('AppAudit.observations')"
              rules="required"
            >
              <b-form-group
                :label="$t('AppAudit.observations')"
              >
                <b-form-textarea
                  v-model="selectedOperationAudit.observaciones"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <b-button
          :disabled="autorized"
          @click="onPaymentAuthorization"
        >
          <b-spinner v-if="autorized" small class="mr-1" />
          {{ $t('AppAudit.authAction') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  ref,
} from '@vue/composition-api'

import {
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BFormInput,
  BModal,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInvalidFeedback,
  BFormTextarea,
  BSpinner,
  BMedia,
} from 'bootstrap-vue'
import prevauditService from '@/services/prevaudit.service'
import List from '@/components/List.vue'
import i18n from '@/libs/i18n'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import { required } from '@validations'
import optionService from '@/services/option.service'
import common from '@/libs/common'

export default {
  components: {
    List,

    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BFormInput,
    BModal,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    BSpinner,
    BMedia,

    vSelect,

    AppTimeline,
    AppTimelineItem,

    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    /* Services */
    const {
      fetchOperations,
      fetchOperationAuditEvents,
      createImportFile,
      fetchSXReferenciaNumber,
      fetchOperationLog,
      createPaymentAuthorization,
      getAuthorizationRequest,
    } = prevauditService()

    const {
      fetchOptions,
    } = optionService()

    const { userIsInRole } = common()

    const refOperationsList = ref(null)

    const dateTimeFormater = JSON.parse(localStorage.getItem('stateTime'))
    if (!dateTimeFormater.find(e => e.key === 'formatDatePreAudit')) {
      dateTimeFormater.push({ key: 'formatDatePreAudit', value: false })
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }
    const stateTime = ref(dateTimeFormater.find(e => e.key === 'formatDatePreAudit'))

    let actions = ref([])

    const changeStateTime = () => {
      stateTime.value.value = !stateTime.value.value
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
      const index = actions.value.findIndex(e => e.name === 'dateAndHour')
      actions.value[index].value = stateTime.value.value
    }
    const refConversation = ref(null)
    const autorized = ref(false)
    const referenceNumber = ref('')
    const selectedOperationId = ref(null)
    const refAuthorizationRules = ref(null)
    const selectedOperationAudit = ref({
      hallazgoAutorizacionTipoId: null,
      observaciones: '',
    })
    const showFindingsModal = ref(false)
    const showLogsModal = ref(false)
    const showAuthorizationModal = ref(false)

    const operationLogs = ref([])

    const findingTypes = ref([])
    fetchOptions({ nombreDominio: 'HallazgoAutorizacionTipo' }, data => {
      findingTypes.value = data
    })

    const filters = ref([
      {
        type: 'select',
        name: 'clienteId',
        label: i18n.t('AppAudit.list.filters.client'),
        value: '',
        options: [],
        domainName: 'Clientes',
      },
      {
        type: 'checkbox',
        label: i18n.t('AppAudit.list.filters.FormatDate'),
        value: 0,
        options: [],
      },
    ])

    const filtersOperationFindings = ref([])

    const tableColumns = [
      {
        key: 'preauditorOperacionId',
        label: i18n.t('AppAudit.list.columns.invoice'),
        sortable: true,
        sortkey: 'PreauditorOperacionId',
      },
      {
        key: 'origenAduana',
        label: i18n.t('AppAudit.list.columns.custom'),
        sortable: true,
        sortkey: 'OrigenAduana',
      },
      {
        key: 'origenNumeroReferencia',
        label: i18n.t('AppAudit.list.columns.reference'),
        sortable: true,
        sortkey: 'OrigenNumeroReferencia',
      },
      {
        key: 'fechaAlta',
        label: i18n.t('AppAudit.list.columns.startDate'),
        sortable: true,
        sortkey: 'FechaAlta',
      },
      {
        key: 'numeroOperacion',
        label: i18n.t('AppAudit.list.columns.operation'),
        sortable: true,
        sortkey: 'OrigenNumeroOperacion',
      },
      {
        key: 'origenNumeroPedimento',
        label: i18n.t('AppAudit.list.columns.request'),
        sortable: true,
        sortkey: 'OrigenNumeroPedimento',
      },
      {
        key: 'origenClaveDocumento',
        label: i18n.t('AppAudit.list.columns.documentKey'),
        sortable: true,
        sortkey: 'OrigenClaveDocumento',
      },
      {
        key: 'status',
        label: i18n.t('AppAudit.list.columns.finding'),
        sortable: false,
        sortkey: 'NumeroPedimento',
      },
      {
        key: 'freePayment',
        label: i18n.t('AppAudit.list.columns.status'),
        sortable: false,
        sortkey: 'NumeroPedimento',
      },
      {
        label: i18n.t('AppAudit.list.columns.actions'),
        key: 'actions',
        sortable: false,
      },
    ]

    const tableOperationFindingsColumns = [
      {
        key: i18n.t('AppAudit.list.columns.invoice'),
        label: 'Folio',
        sortable: true,
        sortkey: 'AuditorOperacionEventoId',
      },
      {
        key: 'auditoriaEventoTipo.nombre',
        label: i18n.t('AppAudit.list.columns.finding'),
        sortable: true,
        sortkey: 'AuditorOperacionEventoId',
      },
      {
        key: 'nivelInformacion',
        label: i18n.t('AppAudit.list.columns.lvlinfo'),
        sortable: true,
        sortkey: 'AuditorOperacionEventoId',
      },
      {
        key: 'campo',
        label: i18n.t('AppAudit.list.columns.field'),
        sortable: true,
        sortkey: 'AuditorOperacionEventoId',
      },
      {
        key: 'campoValorTiene',
        label: i18n.t('AppAudit.list.columns.valHas'),
        sortable: true,
        sortkey: 'AuditorOperacionEventoId',
      },
      {
        key: 'campoValorDebeTener',
        label: i18n.t('AppAudit.list.columns.valMustHave'),
        sortable: true,
        sortkey: 'AuditorOperacionEventoId',
      },
    ]

    actions = ref([
      {
        name: 'uploadTemplate',
        aclAction: 'create',
        aclResource: 'AuditoriaMolex',
      },
      {
        name: 'dateAndHour',
        aclAction: 'read',
        aclResource: 'AccionesMejora',
        icon: 'CalendarIcon',
        value: stateTime.value.value,
        click: changeStateTime,
      },
    ])

    const actionsOperationFindings = ref([])

    // eslint-disable-next-line arrow-body-style
    const resolveOperationStatusVariant = item => {
      if (item.preauditorOperacionEventos.length > 0) return 'warning'
      if (item.preauditorOperacionEventos.length === 0) return 'success'
      return 'success'
    }
    const resolveOperationFreePaymentVariant = item => {
      if (item.fechaHoraAutorizacionPago) return 'success'
      if (item.fechaHoraSolicitudAutorizacion) return 'warning'
      return 'warning'
    }

    const onShowFindingsModal = item => {
      selectedOperationId.value = item.preauditorOperacionId
      showFindingsModal.value = true
    }

    const onShowLogsModal = item => {
      showLogsModal.value = true
      fetchOperationLog(item.preauditorOperacionId, data => {
        operationLogs.value = data
      })
    }
    const onShowAuthorizationModal = item => {
      selectedOperationId.value = item.preauditorOperacionId
      // eslint-disable-next-line prefer-destructuring
      selectedOperationAudit.value = item
      showAuthorizationModal.value = true
    }
    const onRequestAuthorization = item => {
      getAuthorizationRequest(item.preauditorOperacionId, () => {
        refOperationsList.value.refreshData()
      })
    }

    const tableActions = ref([
      {
        name: 'findings',
        label: i18n.t('AppAudit.list.actionList.viewFindings'),
        aclAction: 'read',
        aclResource: 'AuditoriaMolex',
        click: onShowFindingsModal,
        disabledCondition: item => item.preauditorOperacionEventos.length === 0,
        icon: 'SearchIcon',
      },
      {
        name: 'logs',
        label: i18n.t('AppAudit.list.actionList.viewLogbook'),
        aclAction: 'read',
        aclResource: 'AuditoriaMolex',
        click: onShowLogsModal,
        icon: 'BookOpenIcon',
      },
      {
        name: 'authorizationRequest',
        label: i18n.t('AppAudit.list.actionList.requestAuth'),
        aclAction: 'read',
        aclResource: 'AuditoriaMolex',
        click: onRequestAuthorization,
        disabledCondition: item => item.fechaHoraSolicitudAutorizacion !== undefined || item.fechaHoraAutorizacionPago !== undefined,
        icon: 'UserCheckIcon',
      },
      {
        name: 'authorization',
        label: i18n.t('AppAudit.list.actionList.authorization'),
        aclAction: 'read',
        aclResource: 'AuditoriaMolex',
        click: onShowAuthorizationModal,
        disabledCondition: item => item.fechaHoraAutorizacionPago !== undefined || !(userIsInRole('GerenteOperativo') || userIsInRole('JefeOperativo') || userIsInRole('Administrator')),
        icon: 'CheckCircleIcon',
      },
    ])
    const tableActionsOperationFindings = ref([])
    const fetchEvents = (data, callback) => {
      fetchOperationAuditEvents(selectedOperationId.value, data, callback)
    }

    const resolveVariantLog = event => {
      if (event === 'EXTRACCION DE CAMBIOS') return 'primary'
      if (event === 'AUDITORIA APLICADA') return 'secondary'
      if (event === 'AUTORIZADO') return 'success'
      if (event === 'SOLICITUD AUTORIZACION') return 'warning'
      return ''
    }

    const resolveOperationFreePaymentText = item => {
      if (item.fechaHoraAutorizacionPago) return i18n.t('AppAuditSaai.list.columns.authorize')
      if (item.fechaHoraSolicitudAutorizacion) return i18n.t('AppAuditSaai.list.columns.reqAuth')
      return i18n.t('AppAuditSaai.list.columns.pending')
    }

    const onSearchReference = () => {
      fetchSXReferenciaNumber(referenceNumber.value, () => {
        referenceNumber.value = ''
        refOperationsList.value.refreshData()
      })
    }

    const onPaymentAuthorization = () => {
      autorized.value = true
      refAuthorizationRules.value.validate().then(success => {
        if (success) {
          // callback()
          createPaymentAuthorization(selectedOperationId.value, selectedOperationAudit.value, () => {
            showAuthorizationModal.value = false
            refOperationsList.value.refreshData()
            autorized.value = false
          })
        }
      })
    }

    const {
      getValidationState,
    } = formValidation()

    return {
      refOperationsList,
      refConversation,
      refAuthorizationRules,
      referenceNumber,
      showFindingsModal,
      showLogsModal,
      showAuthorizationModal,
      filters,
      filtersOperationFindings,
      tableColumns,
      tableOperationFindingsColumns,
      tableActions,
      tableActionsOperationFindings,
      actions,
      actionsOperationFindings,
      autorized,

      onPaymentAuthorization,

      operationLogs,
      findingTypes,
      selectedOperationAudit,

      stateTime,
      fetchOperations,
      fetchEvents,
      onSearchReference,
      resolveOperationStatusVariant,
      resolveOperationFreePaymentVariant,
      resolveOperationFreePaymentText,
      resolveVariantLog,

      getValidationState,
      required,

      createImportFile,
    }
  },
}
</script>

<style>

</style>
