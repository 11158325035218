import defaultService from './_default.service'

export default function prevauditService() {
  const defaultMethods = defaultService('Auditorias')

  const fetchOperations = (data, callback) => defaultMethods.fetch('Preauditor', { }, data, callback)

  const fetchSXReferenciaNumber = (operacion, callback) => defaultMethods.fetch('Preauditor/ImportarReferenciaSysExpert/%{operacion}', { operacion }, {}, callback)
  const fetchOperationAuditEvents = (preauditorOperacionId, data, callback) => defaultMethods.fetch('Preauditor/%{preauditorOperacionId}/Hallazgos', { preauditorOperacionId }, data, callback)
  const fetchOperationLog = (preauditorOperacionId, callback) => defaultMethods.fetch('Preauditor/%{preauditorOperacionId}/Bitacora', { preauditorOperacionId }, {}, callback)
  const createPaymentAuthorization = (preauditorOperacionId, data, callback) => defaultMethods.create('Preauditor/AutorizarPago/%{preauditorOperacionId}', { preauditorOperacionId }, true, data, callback)
  const getAuthorizationRequest = (preauditorOperacionId, callback) => defaultMethods.fetch('Preauditor/SolicitarAutorizacion/%{preauditorOperacionId}', { preauditorOperacionId }, {}, callback)
  const createImportFile = (data, callback) => defaultMethods.create('Preauditor/ImportarExcel', { }, true, data, callback)

  return {
    fetchOperations,
    fetchSXReferenciaNumber,
    fetchOperationAuditEvents,
    fetchOperationLog,
    createPaymentAuthorization,
    getAuthorizationRequest,
    createImportFile,
  }
}
